import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button } from '../../../../../components'
import { CollectionPageController } from '../../../../../pages/CollectionPageV2/collection-page-controller'
import { CollectionState, CollectionStateFieldFieldTool, useFieldFieldTools, useSelectedFieldToUpdate } from '../../../../../lib'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useFieldTools } from '../../../../../hooks'
import { FieldToolDialog } from '../../../../../components/FieldToolDialog/FieldToolDialog'
import { WhatsAppField } from '@indigohive/cogfy-types'

export type WhatsAppConfigurationsToolsProps = {
  controller: CollectionPageController
  state: CollectionState
}

export function WhatsAppConfigurationsTools (props: WhatsAppConfigurationsToolsProps) {
  const { controller, state } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state) as WhatsAppField
  useFieldTools(state, selectedField.id)
  const fieldTools = useFieldFieldTools(state)
  const [createFieldToolMenuOpen, setCreateFieldToolMenuOpen] = useState(false)
  const [fieldFieldToolToUpdate, setFieldFieldToolToUpdate] = useState<CollectionStateFieldFieldTool | null>(null)

  return (
    <>
      <div className='flex'>
        <span className="font-bold grow text-sm pb-2">
          {t('editFieldDrawer:Tools')}
        </span>
        <Button
          onClick={() => setCreateFieldToolMenuOpen(true)}
          size="xs"
          className="gap-x-1"
        >
          <PlusIcon className="w-4 h-4" />
          {t('Add')}
        </Button>
      </div>
      {fieldTools?.map(tool => (
        <div className="flex flex-row items-center" key={tool.id}>
          <span className="basis-1/2 truncate text-sm">{tool.name}</span>
          <div className="ml-auto flex">
            <Button
              size="xs"
              className="mr-2"
              onClick={() => setFieldFieldToolToUpdate(tool)}
            >
              <PencilIcon className="w-4 h-4" />
            </Button>
            <Button
              size="xs"
              onClick={() => controller.onDeleteFieldFieldToolClick(tool.id, tool.fieldId)}
            >
              <TrashIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>
      ))}

      {(!fieldTools || fieldTools.length === 0) && (
        <span className="label-text py-1">{t('chatConfig:No tool added to this field')}</span>
      )}

      {(createFieldToolMenuOpen || fieldFieldToolToUpdate) && (
        <FieldToolDialog
          fieldId={selectedField.id}
          open={createFieldToolMenuOpen || Boolean(fieldFieldToolToUpdate)}
          fieldFieldTool={fieldFieldToolToUpdate}
          onClose={() => {
            setCreateFieldToolMenuOpen(false)
            setFieldFieldToolToUpdate(null)
          }}
          controller={controller}
        />
      )}
    </>
  )
}
