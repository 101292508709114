import { AuthorRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../FieldCell'
import clsx from 'clsx'

export function AuthorCell (props: FieldCellProps) {
  const { record, field, viewField } = props

  const textWrap = viewField.config?.ui?.textWrap

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      {(record.properties[field.id] as AuthorRecordProperty)?.author?.value?.name}
    </span>
  )
}
