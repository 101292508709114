import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { FieldToolType } from '@indigohive/cogfy-types'

export function useFieldToolsSchemas (
  type?: FieldToolType
) {
  const cogfy = useCogfy()

  const getFieldToolsSchemas = useQuery({
    queryKey: ['getFieldToolsSchemas'],
    queryFn: ({ signal }) => cogfy.fieldTools.get({ type }, signal)
  })

  return getFieldToolsSchemas
}
