import { FieldToolType } from '@indigohive/cogfy-types'
import * as yup from 'yup'

// TO-DO: Create endpoint to validate field tool inputs and delete this file

const cogfyCreateRecordToolSchema = {
  collectionId: yup.string().required('Collection is a required field')
}

const cogfyRecordSearchToolSchema = {
  objectName: yup.string().required('Object name is a required field'),
  collectionId: yup.string().required('Collection is a required field')
}

const cogfySemanticSearchToolSchema = {
  objectName: yup.string().required('Object name is a required field'),
  collectionId: yup.string().required('Collection is a required field'),
  threshold: yup.number().min(0.01).max(1).required('Threshold is a required field'),
  countDocuments: yup.number().min(1).max(100).required('Count documents is a required field')
}

const httpToolSchema = {
  url: yup.string().url('Must be a valid URL').required('URL is a required field'),
  jsonSchema: yup.string().required('JSON Schema is a required field').test(
    'is-json', 'Must be a valid JSON', value => {
      try {
        JSON.parse(value)
        return true
      } catch {
        return false
      }
    })
}

const sendgridToolSchema = {
  connectionId: yup.string().optional(),
  senderEmail: yup.string().email('Must be a valid email').optional()
}

export const fieldToolValidations: Record<FieldToolType, Record<string, yup.AnyObjectSchema | yup.AnySchema>> = {
  cogfy_create_record: cogfyCreateRecordToolSchema,
  cogfy_record_search: cogfyRecordSearchToolSchema,
  cogfy_semantic_search: cogfySemanticSearchToolSchema,
  http: httpToolSchema,
  sendgrid_send_email: sendgridToolSchema
}

export const nameValidation = yup
  .string()
  .matches(/^[a-zA-Z0-9_-]+$/, 'Name must contain only letters, numbers, _ or -')
  .required('Name is a required field')
