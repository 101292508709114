import { UpdateDateFieldData, UpdateDateRecordProperty } from '@indigohive/cogfy-types'
import { FormattedDate } from '../../../components'
import { FieldCellProps } from '../FieldCell'
import clsx from 'clsx'

export function UpdateDateCell (props: FieldCellProps) {
  const { record, field, viewField } = props

  const textWrap = viewField.config?.ui?.textWrap

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <FormattedDate
        date={(record.properties[field.id] as UpdateDateRecordProperty)?.updateDate?.value}
        format={(field.data as UpdateDateFieldData)?.updateDate?.format ?? 'Pp'}
      />
    </span>
  )
}
