import { Input } from '../../Input'
import { FieldToolInputProps } from '../FieldToolDialog'

export function FieldToolBaseInput (props: FieldToolInputProps) {
  const { values, validations } = props

  return (
    <>
      <Input
        label="Name"
        name="name"
        size="sm"
        type="text"
        helperText={(validations.name.error) ?? ''}
        error={Boolean(validations.name.error)}
        value={values.name ?? ''}
        onChange={event => props.handleChange?.('name', event.target.value)}
      />
      <Input
        label="Description"
        name="description"
        size="sm"
        type="text"
        helperText=""
        value={values.description ?? ''}
        onChange={event => props.handleChange?.('description', event.target.value)}
      />
    </>
  )
}
