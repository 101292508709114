import { FieldToolInputNumber } from '@indigohive/cogfy-types'
import { FieldToolInputProps } from '../FieldToolDialog'
import { Input } from '../../Input'
import clsx from 'clsx'

export function FieldToolNumberInput (props: FieldToolInputProps) {
  const name = props.name ?? ''
  const input = props.input as FieldToolInputNumber
  const value = props.values[name]
  const error = props.validations[name]?.error

  return (
    <Input
      className={clsx(
        input.className
      )}
      label={
        <div className="py-1">
          {input.label}
          {input.required && <span className="text-error"> *</span>}
        </div>
      }
      name={name}
      size="sm"
      type="number"
      helperText={(error) ?? ''}
      error={Boolean(error)}
      step={input.data?.step}
      min={input.data?.min}
      max={input.data?.max}
      value={value ?? ''}
      onChange={event => props.handleChange?.(name, event.target.value)}
    />
  )
}
