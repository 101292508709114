import { UUID, WhatsAppField, WhatsAppFieldData } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, ConfirmDeleteDialog } from '../../../../../components'
import { useCogfy } from '../../../../../hooks'
import { CollectionStateField } from '../../../../../lib'
import { WhatsAppWizard } from './WhatsAppWizard'

export type WhatsAppParametersProps = {
  field: WhatsAppField
  onChange: (fieldId: UUID, data: any) => void
  onClear: (field: CollectionStateField) => void
}

export function WhatsAppParameters (props: WhatsAppParametersProps) {
  const { field, onClear } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const cogfy = useCogfy()

  const phoneNumber = field.data?.whatsApp?.phoneNumber

  const clearWhatsApp = useMutation({
    mutationFn: () => cogfy.fields.clearWhatsApp(field.id),
    onSuccess: () => {
      const oldData = field.data
      const newField = {
        ...field,
        data: {
          ...oldData,
          whatsApp: {
            ...oldData?.whatsApp,
            phoneNumber: undefined
          }
        }
      }

      onClear(newField)
      setOpen(false)
    }
  })

  const handleConfirmation = (data: WhatsAppFieldData['whatsApp']) => {
    props.onChange(
      field.id,
      {
        whatsApp: {
          ...field.data?.whatsApp,
          ...data
        }
      }
    )
  }

  return (
    <>
      {phoneNumber && (
        <>
          <div className="card card-compact bg-[#65D072]">
            <div className="card-body">
              <div>
                <label className="label-text">{t('WhatsApp Business Account ID')}</label>
                <div className="text-sm">{phoneNumber.wabaId}</div>
              </div>
              <div>
                <label className="label-text">{t('editFieldDrawer:Phone number')}</label>
                <div className="text-sm">{`${phoneNumber.name}: ${phoneNumber.displayNumber}`}</div>
              </div>
              <div className="card-actions justify-end">
                <Button
                  size="xs"
                  disabled={clearWhatsApp.isPending}
                  onClick={() => setOpen(true)}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>

          <ConfirmDeleteDialog
            open={open}
            title={t('Clear WhatsApp phone number')}
            message={t('Are you sure you want to clear the WhatsApp phone number?')}
            onClose={() => setOpen(false)}
            onConfirm={() => {
              clearWhatsApp.mutate()
            }}
          />
        </>
      )}
      {!phoneNumber && (
        <WhatsAppWizard
          fieldId={field.id}
          onConfirm={handleConfirmation}
        />
      )}
    </>
  )
}
