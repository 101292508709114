import { BooleanRecordProperty, BooleanViewFieldConfig } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

const DEFAULT_VARIANT = 'checkbox'

export function BooleanCell (props: FieldCellProps) {
  const { record, field, viewField, controller, options } = props
  const checked = (record.properties[field.id] as BooleanRecordProperty)?.boolean?.value ?? false
  const variant = (viewField?.config as BooleanViewFieldConfig)?.boolean?.variant ?? DEFAULT_VARIANT
  const canEdit = options?.canEdit ?? false

  return (
    <label className="flex cursor-pointer px-2 py-1 w-full h-full">
      <input
        type="checkbox"
        checked={checked}
        disabled={!canEdit}
        onChange={event => {
          controller.onUpdateRecordProperties(record.id, {
            [field.id]: {
              type: 'boolean',
              boolean: { value: event.target.checked }
            }
          })
        }}
        className={clsx(
          variant === 'checkbox' && 'checkbox checkbox-sm checked:checkbox-info',
          variant === 'toggle' && 'toggle toggle-sm toggle-info'
        )}
      />
    </label>
  )
}
