import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { GetConnectionsQuery, GetConnectionsResultData, ProviderName, UUID, WhatsAppField, WhatsAppFieldData } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { Input, Mentions, MentionsValue, SelectOverlay, ToolTip } from '../../../../../components'
import { useCogfy } from '../../../../../hooks'
import { CollectionStateField } from '../../../../../lib'

export const logoByProvider: Partial<Record<ProviderName, string>> = {
  aws: '/aws-bedrock-avatar.png',
  openai: '/openai-avatar.png'
}

const MENTIONABLE_FIELD_TYPES = ['json', 'number', 'text']

const WHATSAPP_CONFIGURATION = {
  systemPrompt: {
    default: "You're a helpful assistant."
  },
  temperature: {
    default: 0.7,
    step: 0.1,
    min: 0,
    max: 2
  }
}

export type WhatsAppBaseConfigurationProps = {
  field: WhatsAppField
  fields: CollectionStateField[]
  onChange: (data: WhatsAppFieldData) => void
}

export function WhatsAppBaseConfiguration (props: WhatsAppBaseConfigurationProps) {
  const { field, fields, onChange } = props
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const [mentionValue, setMentionValue] = useState<MentionsValue>({
    parts: typeof field.data?.whatsApp?.systemPrompt === 'string'
      ? [field.data?.whatsApp?.systemPrompt]
      : field.data?.whatsApp?.systemPrompt?.template.strings ?? [WHATSAPP_CONFIGURATION.systemPrompt.default],
    options: typeof field.data?.whatsApp?.systemPrompt === 'string'
      ? []
      : field.data?.whatsApp?.systemPrompt?.template.args.map(arg => ({
        id: arg.fieldId,
        name: fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
      })) ?? []
  })

  const mentionOptions = useMemo(() => {
    return fields
      .filter(f => MENTIONABLE_FIELD_TYPES.includes(f.type))
      .filter(f => f.id !== field.id)
      .map(field => ({ id: field.id, name: field.name }))
  }, fields)

  const query: GetConnectionsQuery = { provider: 'openai', enabled: true }
  const getConnections = useQuery({
    queryKey: ['getConnections', query],
    queryFn: ({ signal }) => cogfy.connections.getList(query, signal)
  })

  const connectionId = field.data?.whatsApp?.connectionId
  const getConnectionModels = useQuery({
    queryKey: ['getConnectionModels', connectionId],
    queryFn: ({ signal }) => cogfy.connections.getModels(connectionId!, signal),
    enabled: Boolean(connectionId)
  })

  const connectionOptions = [
    { label: t('editFieldDrawer:Select a connection'), value: '', disabled: true },
    ...(getConnections.data?.data ?? []).map(connection => ({
      label: (
        <div className="flex items-center gap-2">
          {logoByProvider[connection.provider] && (
            <img
              src={logoByProvider[connection.provider]}
              alt={`${connection.provider}-logo`}
              className="h-5 w-5 rounded-md"
            />
          )}
          <span className="grow">{formatConnectionLabel(connection)}</span>
          {connection.owner === 'cogfy' && (<span className="opacity-70 text-xs">{connection.owner}</span>)}
        </div>
      ),
      value: connection.id
    }))
  ]

  const connectionsModelsLength = getConnectionModels.data?.data.length ?? 0
  const modelOptions = [
    {
      label: connectionsModelsLength > 0
        ? t('editFieldDrawer:Select a model')
        : t('editFieldDrawer:No models found'),
      value: '',
      disabled: true
    },
    ...(getConnectionModels.data?.data ?? []).map(model => ({
      label: model.label,
      value: model.model
    }))
  ]

  const handleChange = (data: WhatsAppFieldData['whatsApp']) => {
    const newData = {
      whatsApp: {
        ...field.data?.whatsApp,
        ...data
      }
    }
    onChange(newData)
  }

  const handleMentionsChange = useDebouncedCallback((data: MentionsValue) => {
    const newData: WhatsAppFieldData['whatsApp'] = {
      ...field.data?.whatsApp,
      systemPrompt: {
        type: 'template',
        template: {
          strings: data.parts,
          args: data.options.map(option => ({ fieldId: option.id as UUID }))
        }
      }
    }

    handleChange(newData)
  }, 300)

  return (
    <>
      <SelectOverlay
        label={t('Connection')}
        value={field.data?.whatsApp?.connectionId ?? ''}
        options={connectionOptions}
        disabled={getConnections.isLoading}
        onChange={value => handleChange({ connectionId: value as UUID })}
        error={!field.data?.whatsApp?.connectionId}
      />
      <SelectOverlay
        label={
          <div className="flex items-center">
            <span>{t('Model')}</span>
            <span
              className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
              data-tip={t('ToolTipDescription:The model to use for the prompt')}
            >
              <InformationCircleIcon className="w-5 h-5 text-slate-400" />
            </span>
          </div>
        }
        value={field.data?.whatsApp?.model ?? ''}
        options={modelOptions}
        disabled={!connectionId || getConnectionModels.isLoading || connectionsModelsLength === 0}
        onChange={value => handleChange({ model: value })}
        error={!field.data?.whatsApp?.model}
      />
      <div>
        <div className="flex items-center">
          <span className="label-text py-1">{t('Instructions')}</span>
          <span
            className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
            data-tip={t('ToolTipDescription:Instruction provides context, instructions, and guidelines to the model before presenting it with a question or task')}
          >
            <InformationCircleIcon className="w-5 h-5 text-slate-400" />
          </span>
        </div>
        <Mentions
          error={mentionValue.parts.length === 1 && !mentionValue.parts[0]}
          options={mentionOptions}
          value={mentionValue}
          onChange={value => {
            setMentionValue(value)
            handleMentionsChange(value)
          }}
        />
      </div>
      <label>
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Temperature')}</span>
          <ToolTip
            description={t('ToolTipDescription:Temperature adjusts the creativity of AI responses, making it more or less predictable.')}
            type="info"
          />
        </div>
        <Input
          size="sm"
          step={WHATSAPP_CONFIGURATION.temperature.step}
          min={WHATSAPP_CONFIGURATION.temperature.min}
          max={WHATSAPP_CONFIGURATION.temperature.max}
          type="number"
          value={field.data?.whatsApp?.temperature ?? WHATSAPP_CONFIGURATION.temperature.default}
          onChange={event => handleChange({ temperature: Number(event.target.value) })}
        />
      </label>
    </>
  )
}

export function formatConnectionLabel (connection: GetConnectionsResultData) {
  switch (connection.provider) {
    case 'aws':
      return `${connection.name} (${connection.values.find(value => value.name === 'region')?.plainTextValue})`
    case 'openai':
      return `${connection.name}`
    default:
      return `${connection.name}`
  }
}
