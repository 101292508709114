import { useEffect, useRef } from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { Button } from '../Button'
import prettyBytes from 'pretty-bytes'

export type PreviewFileDialogProps = {
  filename: string
  fileUrl: string
  fileType: string
  fileSize: number
  imageDimensions?: { height: number, width: number }
  open: boolean
  onClose: () => void
}

export function PreviewFileDialog (props: PreviewFileDialogProps) {
  const { filename, open, fileType, fileSize, imageDimensions, fileUrl, onClose } = props
  const dialogRef = useRef<HTMLDialogElement>(null)

  const isImage = fileType.match('image')
  const isVideo = fileType.match('video')

  useEffect(() => {
    if (open) {
      dialogRef.current?.showModal()
    } else {
      dialogRef.current?.close()
    }
  }, [open])

  useEffect(() => {
    const dialog = dialogRef.current

    dialog?.addEventListener('close', onClose)

    return () => {
      dialog?.removeEventListener('close', onClose)
    }
  }, [])

  return (
    <dialog ref={dialogRef} className="modal cursor-default">
      <div className="modal-box max-h-full max-w-full w-5/12 h-2/3 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <span className="text-lg truncate">{filename}</span>
          <Button
            onClick={onClose}
            circle
            ghost
            size="sm"
          >
            <XMarkIcon className="h-6 w-6" />
          </Button>
        </div>
        <div className="h-full w-full flex">
          {isImage && <img src={`${fileUrl}`} className="h-full object-contain aspect-video rounded-sm mx-auto" />}
          {isVideo && <video controls src={`${fileUrl}`} className="mx-auto my-auto"/> }
        </div>
        <span className="text-md text-slate-500"> {`${imageDimensions?.height}x${imageDimensions?.width}, ${prettyBytes(fileSize)}`}</span>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}
