import { ChatRecordProperty } from '@indigohive/cogfy-types'
import { useNavigate } from 'react-router-dom'
import { useCogfy, useWorkspaceSlug } from '../../../hooks'
import { FieldCellProps } from '../FieldCell'
import { PreviewChatContent } from './PreviewChatContent'
import { t } from 'i18next'
import clsx from 'clsx'
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'

export function ChatCell (props: FieldCellProps) {
  const { collectionId, record, field, viewField } = props
  const cogfy = useCogfy()
  const navigate = useNavigate()
  const workspace = useWorkspaceSlug()

  const chatResult = record.properties[field.id] as ChatRecordProperty
  const lastMessage = chatResult?.chat?.value?.lastMessage
  const date = lastMessage?.sendDate
  const textWrap = viewField.config?.ui?.textWrap

  async function handleClick (): Promise<void> {
    if (!chatResult?.chat?.value?.chatId) {
      await cogfy.chats.create({ recordId: record.id, fieldId: field.id, collectionId }).then(newChatId => {
        navigate(`/${workspace}/chats/${newChatId.id}`)
      })
    } else {
      navigate(`/${workspace}/chats/${chatResult.chat.value.chatId}`)
    }
  }

  return (
    <>
      {lastMessage && (
        <PreviewChatContent
          onClick={() => { handleClick() }}
          date={date}
          lastMessage={lastMessage}
          textWrap={textWrap}
        />
      )}
      {!lastMessage && (
        <div
        onClick={() => { handleClick() }}
        className={clsx(
          'h-full',
          'w-full',
          'px-2',
          'py-1',
          'flex',
          'items-start',
          'hover:bg-base-200',
          'active:bg-base-300',
          'gap-1',
          'text-gray-600',
          !textWrap && 'truncate'
        )}
      >
          <div className="grow line-clamp-2">
            <span className="font-medium text-gray-600 inline-flex gap-1.5 items-center mr-1">
              <ChatBubbleLeftEllipsisIcon className="w-3 h-3" />
              {t('Start chat')}
            </span>
          </div>
        </div>
      )}
    </>
  )
}
