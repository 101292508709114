import { ChatField, GetConnectionsQuery, ProviderName, UUID } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup'
import { Input, Mentions, MentionsValue, SelectOverlay, ToolTip } from '../../../../../components'
import { useCogfy } from '../../../../../hooks'
import { CollectionState, useFields, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../../../pages/CollectionPageV2/collection-page-controller'
import { formatConnectionLabel, logoByProvider } from '../../WhatsAppConfigurations/components'
import { ChatConfigurationsValues } from '..'

const defaultInitialMessage = 'Hello! How may I help you?'
const minTemperature = 0
const maxTemperature = 2.0
const validationSchema = yup.object().shape({
  temperature: yup.number().min(minTemperature).max(maxTemperature)
})

export type ChatConfigurationsBaseProps = {
  controller: CollectionPageController
  state: CollectionState
  values: ChatConfigurationsValues
  onChange: (prop: keyof ChatConfigurationsValues, value: any) => void
}

export function ChatConfigurationsBase (props: ChatConfigurationsBaseProps) {
  const { controller, state, values, onChange } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state) as ChatField
  const fields = useFields(state)
  const cogfy = useCogfy()

  const query: GetConnectionsQuery = {
    providers: ['aws', 'openai'],
    enabled: true
  }
  const getConnections = useQuery({
    queryKey: ['getConnections', query],
    queryFn: ({ signal }) => cogfy.connections.getList(query, signal)
  })

  const connectionId = selectedField.data?.chat?.connectionId
  const getConnectionModels = useQuery({
    queryKey: ['getConnectionModels', connectionId],
    queryFn: ({ signal }) => cogfy.connections.getModels(connectionId!, signal),
    enabled: Boolean(connectionId)
  })

  const onChangeIntructions = useDebouncedCallback((newValue: MentionsValue) => {
    controller.onUpdateFieldData(
      selectedField.id,
      {
        chat: {
          ...selectedField?.data?.chat,
          prompt: {
            type: 'template',
            template: {
              strings: newValue.parts,
              args: newValue.options.map(option => ({ fieldId: option.id as UUID }))
            }
          }
        }
      }
    )
  }, 300)

  const onChangeInitialMessage = useDebouncedCallback((newValue: MentionsValue) => {
    controller.onUpdateFieldData(
      selectedField.id,
      {
        chat: {
          ...selectedField?.data?.chat,
          initialMessage: {
            type: 'template',
            template: {
              strings: newValue.parts,
              args: newValue.options.map(option => ({ fieldId: option.id as UUID }))
            }
          }
        }
      }
    )
  }, 300)

  const mentionOptions = useMemo(() => {
    return fields!
      .filter(field => field.type === 'text' || field.type === 'json' || field.type === 'number')
      .filter(field => field.id !== selectedField?.id)
      .map(field => ({ id: field.id, name: field.name }))
  }, fields!)

  const model = values.model
  const promptTemplate = values.promptTemplate
  const initialMessage = values.initialMessage
  const temperature = values.temperature
  const isTemperatureValid = validationSchema.isValidSync({ temperature })

  const connectionOptions = [
    { label: t('editFieldDrawer:Select a connection'), value: '', disabled: true },
    ...(getConnections.data?.data ?? []).map(connection => ({
      label: (
        <div className="flex items-center gap-2">
          {logoByProvider[connection.provider] && (
            <img
              src={logoByProvider[connection.provider]}
              alt={`${connection.provider}-logo`}
              className="h-5 w-5 rounded-md"
            />
          )}
          <span className="grow">{formatConnectionLabel(connection)}</span>
          {connection.owner === 'cogfy' && (<span className="opacity-70 text-xs">{connection.owner}</span>)}
        </div>
      ),
      value: connection.id
    }))
  ]
  const connectionsModelsLength = getConnectionModels.data?.data.length ?? 0
  const modelOptions = [
    {
      label: connectionsModelsLength > 0
        ? t('editFieldDrawer:Select a model')
        : t('editFieldDrawer:No models found'),
      value: '',
      disabled: true
    },
    ...(getConnectionModels.data?.data ?? []).map(model => ({
      label: model.label,
      value: `${model.provider}::${model.model}`
    }))
  ]

  return (
    <>
      <SelectOverlay
        label={t('Connection')}
        value={selectedField.data?.chat?.connectionId ?? ''}
        options={connectionOptions}
        disabled={getConnections.isLoading}
        onChange={value => controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, connectionId: value as UUID } })}
        error={!selectedField.data?.chat?.connectionId}
      />
      <SelectOverlay
        label={
          <>
            <div className="flex items-center">
              <span className="label-text">
                {t('Model')}
                <span className="text-error"> *</span>
              </span>
              <ToolTip
                description={t('ToolTipDescription:The model to use for the prompt')}
                type="info"
              />
            </div>
          </>
        }
        options={modelOptions}
        value={(selectedField.data?.chat?.model && selectedField.data?.chat?.provider)
          ? `${selectedField.data?.chat?.provider}::${selectedField.data?.chat?.model}`
          : ''
        }
        disabled={!connectionId || getConnectionModels.isLoading || connectionsModelsLength === 0}
        error={!model}
        onChange={value => {
          const split = value.split('::')
          const provider = split[0] as ProviderName
          const model = split[1]

          controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, provider, model } })
        }}
      />
      <label className="form-control w-full">
        <div className="flex items-center">
          <span className="label-text py-1">{t('Instructions')}</span>
          <ToolTip
            description={t('ToolTipDescription:Instruction provides context, instructions, and guidelines to the model before presenting it with a question or task')}
            type="info"
          />
        </div>
        <Mentions
          error={promptTemplate.parts.length === 1 && !promptTemplate.parts[0]}
          options={mentionOptions}
          value={promptTemplate}
          onChange={value => {
            onChange('promptTemplate', value)
            onChangeIntructions(value)
          }}
        />
      </label>
      <label className="form-control w-full">
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Set custom initial message')}</span>
          <ToolTip
            description={t('ToolTipDescription:Initial message shown in chat')}
            type="info"
          />
        </div>
        <Mentions
          placeholder={defaultInitialMessage}
          options={mentionOptions}
          value={initialMessage}
          onChange={value => {
            onChange('initialMessage', value)
            onChangeInitialMessage(value)
          }}
        />
      </label>
      <label className="form-control w-full">
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Temperature')}</span>
          <ToolTip
            description={t('ToolTipDescription:Temperature adjusts the creativity of AI responses, making it more or less predictable.')}
            type="info"
          />
        </div>
        <Input
          size="sm"
          step={0.1}
          min={minTemperature}
          max={maxTemperature}
          type="number"
          error={!isTemperatureValid}
          value={temperature}
          onChange={event => onChange('temperature', (Number(event.target.value)))}
          onBlur={_ => { isTemperatureValid && controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, temperature } }) }}
        />
        {!isTemperatureValid && (
          <span className="text-xs text-error pt-1">
            {t('editFieldDrawer:Must be between {{min}} and {{max}}', { min: minTemperature, max: maxTemperature })}
          </span>
        )}
      </label>
    </>
  )
}
