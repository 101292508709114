import { useQuery } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'

export function useCollectionFields (
  collectionId?: UUID
) {
  const cogfy = useCogfy()

  const getCollectionFields = useQuery({
    queryKey: ['getCollectionFields', collectionId],
    queryFn: ({ signal }) => cogfy.collections.getFields(collectionId!, signal),
    enabled: Boolean(collectionId)
  })

  return getCollectionFields
}
