import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { UUID, WhatsAppField, WhatsAppFieldData } from '@indigohive/cogfy-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Input, MultiSelect } from '../../../../components'
import { useCollections } from '../../../../hooks'
import { CollectionStateField, useFields, useSelectedFieldToUpdate } from '../../../../lib'
import { FieldConfigurationsProps } from '../../FieldConfigurations'
import { WhatsAppBaseConfiguration, WhatsAppConfigurationsTools, WhatsAppParameters } from './components'

const defaultThreshold = 0.45
const minThreshold = 0.01
const maxThreshold = 1

const thresholdValidation = yup.number().min(minThreshold).max(maxThreshold)

export function WhatsappConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const fields = useFields(state)
  const selectedField = useSelectedFieldToUpdate(state) as WhatsAppField
  const getCollections = useCollections({ type: 'database' })
  const collections = getCollections?.data?.data.filter(collection => collection.type !== 'folder')
  const allCollectionIds = collections?.map(collection => collection.id) ?? []
  const [collectionIds, setCollectionIds] = useState(selectedField.data?.whatsApp?.collectionIds ?? [])
  const [threshold, setThreshold] = useState(selectedField.data?.whatsApp?.threshold ?? defaultThreshold)

  useEffect(() => {
    setCollectionIds(selectedField.data?.whatsApp?.collectionIds ?? [])
    setThreshold(selectedField.data?.whatsApp?.threshold ?? defaultThreshold)
  }, [selectedField.data])

  const isThresholdValid = thresholdValidation.isValidSync(threshold)

  const handleWhatsAppFieldDataChange = (data: WhatsAppFieldData) => {
    controller.onUpdateFieldData(selectedField.id, data)
  }

  const handleClearWhatsApp = (field: CollectionStateField) => {
    state.setSelectedFieldToUpdate(field)
  }

  return (
    <>
      <WhatsAppParameters
        field={selectedField}
        onChange={(fieldId, data) => controller.onUpdateFieldData(fieldId, data)}
        onClear={handleClearWhatsApp}
      />
      <WhatsAppBaseConfiguration
        field={selectedField}
        fields={fields ?? []}
        onChange={handleWhatsAppFieldDataChange}
      />

      <div className="divider h-1 my-2" />

      {collections && (
        <>
          <div className='flex items-center'>
            <span className="label-text py-1">{t('Collections')}</span>
            <span
              className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
              data-tip={t('ToolTipDescription:The collections which the chat can search for documents')}
            >
              <InformationCircleIcon className="w-5 h-5 text-slate-400" />
            </span>
          </div>
          <MultiSelect
            options={collections.map(collection => ({ label: collection.name ?? t('Untitled'), value: collection.id }))}
            labels={{
              selectItems: t('editFieldDrawer:Select collections'),
              allItems: t('editFieldDrawer:All collections'),
              noResultsFound: t('No collections found')
            }}
            selected={collectionIds}
            onChange={(value: string) => {
              setCollectionIds(prevSelected =>
                prevSelected.includes(value as UUID)
                  ? prevSelected.filter(item => item !== value)
                  : [...prevSelected, value as UUID]
              )
            }}
            onClearSelections={() => setCollectionIds([])}
            onClose={() => controller.onUpdateFieldData(selectedField.id, { whatsApp: { ...selectedField.data?.whatsApp, collectionIds } })}
            onSelectAll={() => setCollectionIds([...allCollectionIds])}
          />
        </>
      )}
      <label className="form-control w-full pt-1">
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Minimum similarity')}</span>
          <span
            className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
            data-tip={t('ToolTipDescription:Minimum similarity between the user question and the content of the documents')}
          >
            <InformationCircleIcon className="w-5 h-5 text-slate-400" />
          </span>
        </div>
        <Input
          size="sm"
          type="number"
          value={threshold}
          onChange={event => setThreshold(Number(event.target.value))}
          onBlur={_ => controller.onUpdateFieldData(selectedField.id, { whatsApp: { ...selectedField.data?.whatsApp, threshold } })}
        />
        {!isThresholdValid && <span className="text-xs text-error pt-1">{t('editFieldDrawer:Must be between 0.01 and 1')}</span>}
      </label>

      <div className="divider h-1 my-2" />

      <WhatsAppConfigurationsTools state={state} controller={controller} />
    </>
  )
}
