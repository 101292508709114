import { VectorRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../FieldCell'

export function VectorCell (props: FieldCellProps) {
  return (
    <span className="px-2 py-1">
      {(props.record.properties[props.field.id] as VectorRecordProperty)?.vector?.value?.count}
    </span>
  )
}
