import { ChatFieldData, WhatsAppFieldData } from '@indigohive/cogfy-types'
import { CollectionStateField } from '../lib'

export function hasMissingData (field: CollectionStateField): boolean {
  switch (field.type) {
    case 'chat':
      return (
        !field.data ||
        !(field.data as ChatFieldData).chat?.connectionId ||
        !(field.data as ChatFieldData).chat?.model
      )
    case 'whatsApp':
      return (
        !field.data ||
        !(field.data as WhatsAppFieldData).whatsApp?.connectionId ||
        !(field.data as WhatsAppFieldData).whatsApp?.model ||
        !(field.data as WhatsAppFieldData).whatsApp?.phoneNumber
      )
    default:
      return false
  }
}
