import * as yup from 'yup'
import {
  FieldToolBaseInput,
  FieldToolBooleanInput,
  FieldToolCollectionInput,
  FieldToolConnectionInput,
  FieldToolFieldInput,
  FieldToolNumberInput,
  FieldToolTextInput
} from './subcomponents'
import { FieldToolInput, FieldToolType, UUID } from '@indigohive/cogfy-types'
import { Fragment, ReactNode, useMemo, useState } from 'react'
import { Button, Dialog, Select } from '../../components'
import { fieldToolValidations, mapInputsToValues, nameValidation, saveFieldTool } from './helpers'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import { CollectionStateFieldFieldTool } from '../../lib'
import { useFieldToolsSchemas } from '../../hooks'

export type CreateFieldToolDialogProps = {
  fieldId: UUID
  open: boolean
  fieldFieldTool: CollectionStateFieldFieldTool | null
  controller?: CollectionPageController
  onClose: () => void
}

export type FieldToolInputProps = {
  values: Record<string, any>
  validations: Record<string, { schema: YupSchema, error: string | null }>
  fieldFieldTool?: CollectionStateFieldFieldTool | null
  name?: string
  input?: FieldToolInput
  handleChange?: (name: string, value: any) => void
}

type YupSchema = yup.AnyObjectSchema | yup.AnySchema

export function FieldToolDialog (props: CreateFieldToolDialogProps) {
  const getFieldTools = useFieldToolsSchemas()
  const fieldTools = getFieldTools.data?.data

  const options = useMemo(() => {
    const result: { label: ReactNode, value: string, disabled?: boolean }[] = [
      { label: 'Select a tool', value: '', disabled: true }
    ]

    if (fieldTools) {
      result.push(...fieldTools?.map(tool => ({ label: tool.name, value: tool.type })))
    }

    return result
  }, [fieldTools])

  const [fieldToolType, setFieldToolType] = useState<FieldToolType | null>(props.fieldFieldTool?.type ?? null)
  const [values, setValues] = useState<Record<string, any>>({
    name: props.fieldFieldTool?.name ?? '',
    description: props.fieldFieldTool?.description ?? '',
    jsonSchema: JSON.stringify(props.fieldFieldTool?.jsonSchema ?? {}),
    confirm: props.fieldFieldTool?.confirm ?? false,
    ...(props.fieldFieldTool && (
      mapInputsToValues(props.fieldFieldTool.type!, fieldTools, props.fieldFieldTool)
    ))
  })
  const [validations, setValidations] = useState<Record<string, { schema: YupSchema, error: string | null }>>({
    name: { schema: nameValidation, error: '' },
    ...(props.fieldFieldTool && (
      Object.fromEntries(Object.entries(fieldToolValidations?.[props.fieldFieldTool.type!] ?? {}).map(([name, validation]) => [name, { schema: validation, error: null }]))
    ))
  })
  const inputs = fieldTools?.find(tool => tool.type === fieldToolType)?.inputsSchema?.inputs ?? {}

  const handleChange = (name: string, value: any) => {
    const validationSchema = validations?.[name]?.schema

    if (validationSchema) {
      validInputValue(name, value, validationSchema)
    }

    setValues(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleToolTypeChange = (type: FieldToolType) => {
    const inputValues = mapInputsToValues(type, fieldTools)
    const toolValidations = fieldToolValidations?.[type] ?? {}
    const inputValidations = Object.fromEntries(Object.entries(toolValidations).map(([name, validation]) => [name, { schema: validation, error: null }]))

    setValues(prev => ({ ...prev, jsonSchema: JSON.stringify({}), confirm: false, ...inputValues }))
    setValidations(({ ...inputValidations, name: { schema: nameValidation, error: '' } }))
    setFieldToolType(type)
  }

  const onSubmit = () => {
    let hasError = false

    for (const [name, value] of Object.entries(values)) {
      const validationSchema = validations?.[name]?.schema

      if (validationSchema) {
        const isValid = validInputValue(name, value, validationSchema)

        if (!isValid) hasError = true
      }
    }

    if (!hasError && fieldToolType) {
      saveFieldTool(fieldToolType, props.fieldId, values, props.controller, fieldTools, props.fieldFieldTool)
      props.onClose()
    }
  }

  const validInputValue = (name: string, value: any, validationSchema: YupSchema) => {
    try {
      validationSchema.validateSync(value)
      setValidations(prev => ({ ...prev, [name]: { ...prev[name], error: null } }))
      return true
    } catch (error: any) {
      const errorMessage = (error as yup.ValidationError)?.errors?.[0]
      setValidations(prev => ({ ...prev, [name]: { ...prev[name], error: errorMessage } }))
      return false
    }
  }

  const hasError = Object.values(validations).some(validation => validation.error)

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
    >
      <h2 className="font-bold grow">
        Add tool
      </h2>
      <form>
        <div className="grid py-4">
          <FieldToolBaseInput
            values={values}
            validations={validations}
            handleChange={handleChange}
          />

          {!props.fieldFieldTool && (
            <Select
              className="mb-5"
              label="Type"
              name="fieldToolType"
              size="sm"
              options={options}
              value={fieldToolType ?? ''}
              onChange={event => handleToolTypeChange(event.target.value as FieldToolType)}
            />
          )}

          {Object.entries(inputs).map(([name, input]) => (
            <Fragment key={name}>
              {input.type === 'boolean' && (
                <FieldToolBooleanInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  handleChange={handleChange}
                />
              )}

              {input.type === 'collection' && (
                <FieldToolCollectionInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  handleChange={handleChange}
                />
              )}

              {input.type === 'connection' && (
                <FieldToolConnectionInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  handleChange={handleChange}
                />
              )}

              {input.type === 'field' && (
                <FieldToolFieldInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  fieldFieldTool={props.fieldFieldTool}
                  handleChange={handleChange}
                />
              )}

              {input.type === 'number' && (
                <FieldToolNumberInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  handleChange={handleChange}
                />
              )}

              {input.type === 'text' && (
                <FieldToolTextInput
                  name={name}
                  input={input}
                  values={values}
                  validations={validations}
                  handleChange={handleChange}
                />
              )}
            </Fragment>
          ))}
        </div>
        <div className="flex gap-4 justify-between">
          <Button
            size="sm"
            type="button"
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={hasError || !fieldToolType}
            onClick={() => onSubmit()}
          >
            {props.fieldFieldTool ? 'Update' : 'Add'}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}
