import { FieldToolInputBoolean } from '@indigohive/cogfy-types'
import { FieldToolInputProps } from '../FieldToolDialog'
import { Toggle } from '../../Toggle'
import clsx from 'clsx'

export function FieldToolBooleanInput (props: FieldToolInputProps) {
  const name = props.name ?? ''
  const input = props.input as FieldToolInputBoolean
  const value = props.values[name]

  return (
    <div className="mb-4">
      <Toggle
        className={clsx(
          input.className
        )}
        color="primary"
        label={input.label}
        helperText={input.description}
        checked={value as boolean}
        name={name}
        value={value ?? false}
        onChange={event => props.handleChange?.(name, event.target.checked)}
      />
    </div>
  )
}
