import { InformationCircleIcon } from '@heroicons/react/24/outline'

export type ToolTipProps = {
  description: string
  type: 'info'
}

export function ToolTip (props: ToolTipProps) {
  const { description, type } = props

  return (
    <span
      className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
       data-tip={description}
      >
      {type === 'info' && <InformationCircleIcon className="w-5 h-5 text-slate-400" />}
    </span>
  )
}
