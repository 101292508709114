import { FieldToolInputCollection } from '@indigohive/cogfy-types'
import { FieldToolInputProps } from '../FieldToolDialog'
import { Select } from '../../Select'
import { useCollections } from '../../../hooks'
import clsx from 'clsx'

export function FieldToolCollectionInput (props: FieldToolInputProps) {
  const name = props.name ?? ''
  const input = props.input as FieldToolInputCollection
  const value = props.values[name]
  const multiple = Boolean(input.data?.multiple)
  const error = props.validations[name]?.error

  const getCollections = useCollections({ type: 'database' })
  const collections = getCollections.data?.data ?? []
  const options = [
    { label: 'Select a collection', value: '', disabled: true },
    ...collections.map(collection => ({ label: collection.name ?? 'Untitled', value: collection.id }))
  ]

  return (
    <>
      {!multiple && (
        <Select
          className={clsx(
            input.className
          )}
          label={
            <div className="py-1">
              {input.label}
              {input.required && <span className="text-error"> *</span>}
            </div>
          }
          name={name}
          size="sm"
          options={options}
          helperText={(error) ?? ''}
          error={Boolean(error)}
          value={value ?? ''}
          onChange={event => props.handleChange?.(name, event.target.value)}
        />
      )}
    </>
  )
}
