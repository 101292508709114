import { WhatsAppRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../FieldCell'
import { useWorkspaceSlug } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import { PreviewChatContent } from '.'
import { t } from 'i18next'
import { formatPhoneNumber } from '../../../helpers'

export function WhatsAppCell (props: FieldCellProps) {
  const { field, record, viewField } = props

  const workspace = useWorkspaceSlug()
  const navigate = useNavigate()

  const whatsAppResult = record.properties[field.id] as WhatsAppRecordProperty

  const chatId = whatsAppResult?.whatsApp?.value?.chatId
  const lastMessage = whatsAppResult?.whatsApp?.value?.lastMessage
  const phoneNumber = whatsAppResult?.whatsApp?.value?.phoneNumber
  const textWrap = viewField.config?.ui?.textWrap

  function handleClick (): void {
    navigate(`/${workspace}/chats/${chatId}`)
  }

  return (
    <>
      {phoneNumber && lastMessage?.content && (
        <PreviewChatContent
          onClick={handleClick}
          date={lastMessage?.sendDate}
          lastMessage={lastMessage}
          textWrap={textWrap}
          phone={formatPhoneNumber(phoneNumber)}
        />
      )}
      {!phoneNumber && !lastMessage?.content && (
        <span className='px-2 py-1'>
          { t('No phone number')}
        </span>
      )}
      {phoneNumber && !lastMessage?.content && (
        <span className='px-2 py-1'>
          { t('No messages found') }
        </span>
      )}
    </>
  )
}
