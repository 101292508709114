import { TextRecordProperty, TextViewFieldConfig } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'
import { FormattedText } from '../../../components'

export function TextCell (props: FieldCellProps) {
  const { field, record, viewField } = props

  const textWrap = viewField.config?.ui?.textWrap
  const viewFieldConfig = viewField.config as TextViewFieldConfig
  const formatText = viewFieldConfig?.text?.textFormat

  const value = (record.properties[field.id] as TextRecordProperty)?.text?.value

  return (
    <div
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      {formatText
        ? <FormattedText text={value ?? null} />
        : <>{value}</>}
    </div>
  )
}
