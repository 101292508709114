import axios from 'axios'
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline'
import type { FileFieldData, FileRecordProperty, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Button, ConfirmDeleteDialog, PreviewFileDialog } from '../../../components'
import { useCogfy, useToasts } from '../../../hooks'
import { FieldCellProps } from '../FieldCell'
import { useEffect, useRef, useState } from 'react'
import { validFile } from '../../../helpers'
import prettyBytes from 'pretty-bytes'

function hasPreview (type: string): boolean {
  return Boolean(type.match('image|video'))
}

export function FileCell (props: FieldCellProps) {
  const { collectionId, field, record, viewField } = props

  const cogfy = useCogfy()
  const { t } = useTranslation()
  const toast = useToasts()
  const [fileToDeleteId, setFileToDeleteId] = useState<UUID | null>(null)
  const [loadingProgress, setLoadingProgress] = useState<number | null>(null)
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)
  const [imageDimensions, setImageDimensions] = useState({ height: 0, width: 0 })
  const imageRef = useRef<HTMLImageElement>(null)

  const canUpdateCollection = true
  const fileResult = (record.properties[field.id] as FileRecordProperty)?.file?.value
  const isFileUploading = fileResult?.uploadStatus === 'pending'
  const textWrap = viewField.config?.ui?.textWrap
  const isImage = fileResult?.type.match('image')

  const uploadFile = useMutation({
    mutationFn: async (file: File) => {
      const error = validFile(field.data as FileFieldData, file)

      if (error) return toast.error(t(error))

      const result = await cogfy.files.upload({
        collectionId,
        fieldId: field.id,
        recordId: record.id,
        type: file.type,
        size: file.size,
        name: file.name
      })

      await axios.post(
        result.signedUrl,
        { ...result.fields, file },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const progress = Math.ceil(Math.round(event.loaded * 100) / event.total)
              setLoadingProgress(progress)
            }
          }
        }
      )

      await cogfy.files.finishUpload(result.id)

      setLoadingProgress(null)
    },
    onError: () => toast.error(t('Error on uploading document'))
  })

  const deleteFile = useMutation({
    mutationFn: async (id: UUID) => {
      await cogfy.files.delete(id)
    }
  })

  useEffect(() => {
    if (!imageRef.current) return
    const img = imageRef.current

    const handleLoadImage = () => {
      setImageDimensions({
        height: img.naturalHeight,
        width: img.naturalWidth
      })
    }

    img.complete
      ? handleLoadImage()
      : img.addEventListener('load', handleLoadImage)

    return () => {
      img.removeEventListener('load', handleLoadImage)
    }
  }, [imageRef.current, fileResult])

  return (
    <>
      <div className="text-sm px-2 py-1">
        {isFileUploading && (
          <div className="flex">
            <span className="loading loading-spinner loading-xs"></span>
            {loadingProgress !== null && <span className="text-slate-500 ml-2">{loadingProgress} %</span>}
          </div>
        )}

        {!fileResult && !isFileUploading && (
          <label className="flex cursor-pointer items-center text-slate-500 hover:text-slate-800">
            <input
              disabled={!canUpdateCollection}
              type="file"
              className="hidden"
              onChange={(event) => {
                const file = event.target.files?.[0]

                if (file) {
                  uploadFile.mutate(file)
                }
              }}
            />
            <ArrowUpTrayIcon
              className="w-4 h-4 ml-1 mr-2"
            />
            Upload
          </label>
        )}

        {fileResult && !isFileUploading && (
          <div className="flex items-center gap-2 group">
            {hasPreview(fileResult.type) &&
              <div
                className="flex items-center gap-2 hover:bg-slate-50 rounded-md p-1"
                onClick={() => setPreviewOpen(true)}
              >
                {isImage && <img ref={imageRef} src={`${fileResult.url}`} className="h-8 object-cover aspect-square rounded-sm" />}
                <span
                  className={clsx(
                    textWrap && 'whitespace-pre-wrap',
                    !textWrap && 'truncate'
                  )}
                >
                  {fileResult.name}
                  <span className='text-xs text-slate-400'>{` (${imageDimensions?.height}x${imageDimensions?.width}, ${prettyBytes(fileResult.size)})`}</span>
                </span>
              </div>
            }
            {!hasPreview(fileResult.type) &&
              <a
                href={`${fileResult.url}`}
                target="_blank"
                rel="noreferrer"
                className={clsx(
                  'hover:underline',
                  textWrap && 'whitespace-pre-wrap',
                  !textWrap && 'truncate'
                )}
              >
                {fileResult.name}
              </a>
            }

            {canUpdateCollection && (
              <Button
                onClick={() => setFileToDeleteId(fileResult.id)}
                className={clsx(
                  'opacity-0',
                  'group-hover:opacity-100',
                  'transition-opacity',
                  'duration-300'
                )}
                ghost
                square
                size="xs"
              >
                <TrashIcon className="w-4 h-4" />
              </Button>
            )}
          </div>
        )}
      </div>
      {fileToDeleteId && (
        <ConfirmDeleteDialog
          title={`${t('Delete file')} '${fileResult?.name}'?`}
          open={fileToDeleteId !== null}
          onConfirm={() => {
            if (fileToDeleteId) {
              deleteFile.mutate(fileToDeleteId)
              setFileToDeleteId(null)
            }
          }}
          onClose={() => {
            setFileToDeleteId(null)
          }}
        />
      )}
      {previewOpen && fileResult &&
        <PreviewFileDialog
          filename={fileResult.name}
          fileUrl={fileResult.url}
          fileType={fileResult.type}
          fileSize={fileResult.size}
          imageDimensions={imageDimensions}
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
        />
      }
    </>
  )
}
