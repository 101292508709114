import clsx, { ClassValue } from 'clsx'
import { PropsWithChildren } from 'react'

export type MenuProps = PropsWithChildren & {
  className?: ClassValue
  size?: 'sm' | 'md'
}

export function Menu (props: MenuProps) {
  const { className, size } = props

  return (
    <menu
      className={clsx(
        'menu',
        'border',
        'bg-base-100',
        'shadow',
        'min-w-64',
        'max-w-96',
        'rounded-lg',
        'inline-block',
        'overflow-y-auto',
        size === 'sm' && 'menu-sm',
        className
      )}
    >
      {props.children}
    </menu>
  )
}
