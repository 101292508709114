import {
  Bars3BottomLeftIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  ClockIcon,
  CodeBracketIcon,
  CodeBracketSquareIcon,
  HashtagIcon,
  ListBulletIcon,
  PaperClipIcon,
  TableCellsIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import { FieldType } from '@indigohive/cogfy-types'
import { WhatsAppIcon } from '../components'

type IconComponentType = typeof CheckCircleIcon | typeof WhatsAppIcon

export const FIELD_ICONS_BY_TYPE: Record<FieldType, IconComponentType> = {
  author: UserCircleIcon,
  boolean: CheckCircleIcon,
  chat: ChatBubbleLeftRightIcon,
  createDate: CalendarIcon,
  date: CalendarIcon,
  file: PaperClipIcon,
  json: CodeBracketSquareIcon,
  number: HashtagIcon,
  reference: TableCellsIcon,
  select: ListBulletIcon,
  stopwatch: ClockIcon,
  text: Bars3BottomLeftIcon,
  updateDate: CalendarIcon,
  vector: CodeBracketIcon,
  whatsApp: WhatsAppIcon
}
