import type { GlobalProvider } from '@ladle/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { MemoryRouter } from 'react-router-dom'
import React from 'react'
import '../src/i18n'
import '../src/index.css'

export const Provider: GlobalProvider = ({ children }) => (
  <DndProvider backend={HTML5Backend}>
    {children}
  </DndProvider>
)
