import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export type CollectionTableFooterProps = {
  loading?: boolean
  onNewRowClick: () => void
  previousPageDisabled?: boolean
  nextPageDisabled?: boolean
  onPreviousPageClick?: () => void
  onNextPageClick?: () => void
  canEdit?: boolean
}

function _CollectionTableFooter (props: CollectionTableFooterProps) {
  const {
    loading,
    onNewRowClick,
    previousPageDisabled,
    nextPageDisabled,
    onPreviousPageClick,
    onNextPageClick,
    canEdit = false
  } = props
  const { t } = useTranslation()

  return (
    <div
      className="flex items-center w-full h-8 min-h-8 -mt-[1px] bg-base-100 border-y sticky bottom-0"
    >
      {canEdit && (
        <div
          onClick={onNewRowClick}
          role={loading ? 'none' : 'button'}
          className={clsx(
            loading && 'cursor-not-allowed',
            !loading && 'hover:bg-base-200',
            !loading && 'active:bg-base-300',
            'text-gray-600',
            'h-full',
            'px-4',
            'grow',
            'text-sm',
            'flex',
            'gap-1.5',
            'items-center'
          )}
        >
          <PlusIcon className="h-4 w-4" />
          {t('New record')}
        </div>
      )}
      {!canEdit && <div className="grow" />}
      <div className="flex gap-2 mx-4 py-1 ml-auto pl-4">
        <button
          className="join-item btn btn-xs"
          disabled={previousPageDisabled}
          onClick={onPreviousPageClick}
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </button>
        <button
          className="join-item btn btn-xs"
          disabled={nextPageDisabled}
          onClick={onNextPageClick}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  )
}

export const CollectionTableFooter = memo(_CollectionTableFooter)
