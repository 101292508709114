import { FieldToolInputText } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldToolInputProps } from '../FieldToolDialog'
import { Input } from '../../Input'
import { Textarea } from '../../Textarea'
import { Select } from '../../Select'

export function FieldToolTextInput (props: FieldToolInputProps) {
  const name = props.name ?? ''
  const input = props.input as FieldToolInputText
  const value = props.values[name]
  const error = props.validations[name]?.error
  const multiple = Boolean(input.data?.multiple)
  const textarea = Boolean(input.data?.textarea)
  const options = input.data?.options

  return (
    <>
      {!options && !textarea && (
        <Input
          className={clsx(
            input.className
          )}
          label={
            <div className="py-1">
              {input.label}
              {input.required && <span className="text-error"> *</span>}
            </div>
          }
          name={name}
          size="sm"
          type="text"
          helperText={(error) ?? ''}
          error={Boolean(error)}
          value={value ?? ''}
          onChange={event => props.handleChange?.(name, event.target.value)}
        />
      )}

      {!options && textarea && (
        <Textarea
          className={clsx(
            input.className
          )}
          label={
            <div className="py-1">
              {input.label}
              {input.required && <span className="text-error"> *</span>}
            </div>
          }
          name={name}
          size="sm"
          helperText={(error) ?? ''}
          error={Boolean(error)}
          value={value ?? ''}
          onChange={event => props.handleChange?.(name, event.target.value)}
        />
      )}

      {options && !multiple && !textarea && (
        <Select
          className={clsx(
            input.className
          )}
          label={
            <div className="py-1">
              {input.label}
              {input.required && <span className="text-error"> *</span>}
            </div>
          }
          name={name}
          size="sm"
          options={options}
          helperText={(error) ?? ''}
          error={Boolean(error)}
          value={value ?? ''}
          onChange={event => props.handleChange?.(name, event.target.value)}
        />
      )}
    </>
  )
}
