import clsx from 'clsx'
import { CollectionState, CollectionStateField, CollectionStateViewField, useSelectedCollectionTitleFieldId } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { FIELD_ICONS_BY_TYPE, hasMissingData } from '../../../../../field-types'
import { createElement, useRef } from 'react'
import { FieldType } from '@indigohive/cogfy-types'
import { CollectionTableResizeColHandler } from './CollectionTableResizeColHandler'
import { useDrag, useDrop } from 'react-dnd'
import { BookmarkIcon, ExclamationTriangleIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { Button } from '../../../../../components'

export type CollectionTableHeadProps = {
  viewField: CollectionStateViewField
  field?: CollectionStateField
  index: number
  locked?: boolean | null
  draggedCol?: { col: CollectionStateViewField, change: number } | null
  controller: CollectionPageController
  state: CollectionState
  onDrag: (col: CollectionStateViewField, change: number) => void
  onDragStop: (col: CollectionStateViewField, change: number) => void
  canDrag?: boolean
}

const DEFAULT_FIELD_WIDTH = 180
const MIN_FIELD_WIDTH = 100

export function CollectionTableHead (props: CollectionTableHeadProps) {
  const {
    viewField,
    field,
    index,
    locked,
    draggedCol,
    controller,
    state,
    onDrag,
    onDragStop,
    canDrag = true
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const selectedCollectionTitleFieldId = useSelectedCollectionTitleFieldId(state)
  const showWarning = field
    ? hasMissingData(field)
    : false

  const [{ isDragging }, drag] = useDrag({
    type: 'CollectionTableHead',
    canDrag,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: { viewField, index }
  }, [viewField])

  const [{ isOver }, drop] = useDrop({
    accept: 'CollectionTableHead',
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    drop: (item: { viewField: CollectionStateViewField, index: number }) => {
      controller.onDropViewField(item.viewField, viewField)
    }
  }, [viewField])

  drag(drop(ref))

  return (
    <div
      ref={ref}
      className={clsx(
        'relative',
        'h-full',
        'border-r',
        'border-base-200',
        isDragging && 'opacity-10',
        isOver && 'bg-base-200',
        !locked && [
          'cursor-pointer',
          'hover:bg-base-200',
          'active:bg-base-300'
        ]
      )}
      onClick={event => !locked && field && controller.onFieldClick?.(event, field)}
      onContextMenu={event => {
        if (!locked && field) {
          controller.onFieldClick?.(event, field)
          event.preventDefault()
        }
      }}
      style={{
        width: draggedCol?.col.id === viewField.id
          ? (viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH) + draggedCol.change + 'px'
          : viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH + 'px',
        minWidth: MIN_FIELD_WIDTH + 'px'
      }}
    >
      <div className="flex justify-between items-center h-full px-2 overflow-hidden max-w-full whitespace-nowrap">
        <span className='inline-flex items-center gap-1.5'>
          {field && createElement(
            FIELD_ICONS_BY_TYPE[field.type as FieldType],
            { className: 'w-4 h-4' }
          )}
          {field?.name}
        </span>
        <span className="flex gap-1 text-gray-400">
          {field?.id === selectedCollectionTitleFieldId && <BookmarkIcon className="w-4 h-4" />}
          {field?.operation && <SparklesIcon className="w-4 h-4" />}
        </span>
        {showWarning && (
          <Button
            ghost
            size="xs"
            circle
            onClick={event => {
              if (field && !locked) {
                controller.onEditFieldClick(field)
                event.stopPropagation()
              }
            }}
          >
            <ExclamationTriangleIcon className="w-4 h-4 text-warning" />
          </Button>
        )}
      </div>
      <CollectionTableResizeColHandler
        col={viewField}
        onDrag={onDrag}
        onDragStop={onDragStop}
      />
    </div>
  )
}
