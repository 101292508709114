import { FieldToolType, GetFieldToolsResultData } from '@indigohive/cogfy-types'
import { CollectionStateFieldFieldTool } from '../../../lib'

/**
 * Get the current field tool config values or the default input values.
 * @param inputs The field tool inputs.
 * @param fieldFieldTool The fielf field tool.
 * @returns The input values.
 */
export function mapInputsToValues (
  type: FieldToolType,
  fieldTools?: GetFieldToolsResultData[],
  fieldFieldTool?: CollectionStateFieldFieldTool | null
): Record<string, any> {
  if (fieldFieldTool?.config) {
    return Object.fromEntries(Object.entries(fieldFieldTool.config.data).map(([name, value]) => [name, value]))
  }

  const inputs = fieldTools?.find((schema) => schema.type === type)?.inputsSchema?.inputs

  if (!inputs) return {}

  const inputDefaultValues = Object.fromEntries(Object.entries(inputs).map(([name, input]) => [name, input.data.default]))

  return inputDefaultValues
}
