import { DateFieldData, DateRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FormattedDate } from '../../../components'
import { FieldCellProps } from '../FieldCell'

export function DateCell (props: FieldCellProps) {
  const { record, field, viewField } = props

  const textWrap = viewField.config?.ui?.textWrap

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <FormattedDate
        date={(record.properties[field.id] as DateRecordProperty)?.date?.value}
        format={(field.data as DateFieldData)?.date?.format ?? 'Pp'}
      />
    </span>
  )
}
