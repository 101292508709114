import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { UUID, WhatsAppFieldData } from '@indigohive/cogfy-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Heading, Select } from '../../../../../components'
import { useCogfy, useWorkspaceSlug } from '../../../../../hooks'
import { useMutation, useQuery } from '@tanstack/react-query'

export type WhatsAppWizardProps = {
  fieldId: UUID
  onClose?: () => void
  onConfirm?: (data: WhatsAppFieldData['whatsApp']) => void
}

export function WhatsAppWizard (props: WhatsAppWizardProps) {
  const { fieldId, onClose, onConfirm } = props
  const cogfy = useCogfy()
  const { t } = useTranslation()
  const workspaceSlug = useWorkspaceSlug()
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState<'account' | 'register' | 'webhook' | 'confirmation'>('account')
  const [connectionId, setConnectionId] = useState<UUID>('' as UUID)
  const [phoneNumberId, setPhoneNumberId] = useState<string>('')

  // Queries
  const getConnections = useQuery({
    queryKey: ['getConnections', 'whatsapp'],
    queryFn: ({ signal }) => cogfy.connections.getList({ provider: 'whatsapp' }, signal)
  })
  const getWhatsAppNumbers = useQuery({
    queryKey: ['getWhatsAppNumbers', connectionId],
    queryFn: ({ signal }) => cogfy.connections.getWhatsAppNumbers(connectionId, signal),
    enabled: Boolean(connectionId)
  })

  // Mutations
  const registerWhatsAppNumber = useMutation({
    mutationFn: () => cogfy.connections.registerWhatsAppNumber(connectionId, { phoneNumberId })
  })
  const updateWhatsAppNumberWebhook = useMutation({
    mutationFn: () => cogfy.connections.updateWhatsAppNumberWebhook(connectionId, { fieldId, phoneNumberId })
  })

  // Functions
  const resetSettings = () => {
    setConnectionId('' as UUID)
    setPhoneNumberId('')
    setStep('account')
  }

  const handleClose = () => {
    resetSettings()
    setOpen(false)
    onClose?.()
  }

  const handleConfirmation = () => {
    const connection = connectionsById[connectionId]
    const phoneNumber = phoneNumbersById[phoneNumberId]
    const data = {
      phoneNumber: {
        connectionId,
        id: phoneNumberId,
        name: phoneNumber.name,
        displayNumber: phoneNumber.displayNumber,
        wabaId: connection.values
          ?.find(value => value.name === 'wa_businness_account_id')
          ?.plainTextValue ?? ''
      }
    }
    onConfirm?.(data)
    handleClose()
  }

  const connectionsById = Object.fromEntries(
    getConnections.data?.data.map(connection => [connection.id, connection]) ?? []
  )
  const connectionsOptions = getConnections.data?.data.map(connection => ({
    label: connection.name,
    value: connection.id
  })) ?? []
  const phoneNumbersById = Object.fromEntries(
    getWhatsAppNumbers.data?.data.map(phoneNumber => [phoneNumber.id, phoneNumber]) ?? []
  )
  const phoneNumberOptions = getWhatsAppNumbers.data?.data.map(phoneNumber => ({
    label: `${phoneNumber.name}: ${phoneNumber.displayNumber}${phoneNumber.status === 'unregistered' ? ` (${t('editFieldDrawer:Unregistered')})` : ''}`,
    value: phoneNumber.id,
    disabled: phoneNumber.webhookConfigured && phoneNumber.webhookFieldId !== fieldId
  })) ?? []
  const isNumberUnregistered = getWhatsAppNumbers.data?.data.some(
    phoneNumber => phoneNumber.id === phoneNumberId && phoneNumber.status === 'unregistered'
  )
  const wabaId = connectionsById[connectionId]?.values
    .find(value => value.name === 'wa_businness_account_id')?.plainTextValue
  const facebookURL = `https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${wabaId}`

  return (
    <>
      <label className="form-control">
        <div className="label">
          <span className="label-text">WhatsApp</span>
        </div>
        <Button
          onClick={() => setOpen(true)}
          size="xs"
        >
          {t('editFieldDrawer:Connect WhatsApp')}
        </Button>
      </label>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <div className="h-[300px]">
          {step === 'account' && (
            <div className="flex flex-col h-full space-y-4">
              <div>
                <Heading component='h2'>{t('editFieldDrawer:Connect WhatsApp')}</Heading>
                <p>{t('editFieldDrawer:Select your WhatsApp account and number to send messages')}</p>
              </div>
              <div className="grow space-y-3">
                {connectionsOptions.length === 0 && (
                  <div role="alert" className="alert alert-warning">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <span className="text-sm">{t('editFieldDrawer:There is no WhatsApp account connected')}</span>
                    <div>
                      <Button
                        role="a"
                        to={`/${workspaceSlug}/connections#whatsapp`}
                        size="sm"
                      >
                        {t('Connect')}
                      </Button>
                    </div>
                  </div>
                )}
                <Select
                  options={[
                    { label: t('editFieldDrawer:Select an account'), value: '' },
                    ...connectionsOptions
                  ]}
                  value={connectionId}
                  onChange={event => setConnectionId(event.target.value as UUID)}
                  disabled={getConnections.isSuccess && connectionsOptions.length === 0}
                  size="md"
                />
                <Select
                  options={[
                    { label: t('editFieldDrawer:Select a phone number'), value: '' },
                    ...phoneNumberOptions
                  ]}
                  value={phoneNumberId}
                  onChange={event => setPhoneNumberId(event.target.value)}
                  disabled={
                    !connectionId ||
                    getWhatsAppNumbers.isFetching ||
                    (getWhatsAppNumbers.isSuccess && phoneNumberOptions.length === 0)
                  }
                  size="md"
                />
                <label className="label-text-alt">
                  <em>{t('editFieldDrawer:You can only select a number that is not already connected to another field')}</em>
                </label>
                {(connectionsOptions.length > 0 && getWhatsAppNumbers.isSuccess && phoneNumberOptions.length === 0) && (
                  <div role="alert" className="alert alert-warning">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <span className="text-sm">{t('editFieldDrawer:There is no phone number available')}</span>
                    <div>
                      <a
                        className="btn btn-sm"
                        href={facebookURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('Connect')}
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={handleClose}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (isNumberUnregistered) {
                      registerWhatsAppNumber.mutate()
                      setStep('register')
                    } else {
                      updateWhatsAppNumberWebhook.mutate()
                      setStep('webhook')
                    }
                  }}
                  disabled={!phoneNumberId}
                  color='primary'
                  size="sm"
                >
                  {t('Next')}
                </Button>
              </div>
            </div>
          )}
          {step === 'register' && (
            <div className="flex flex-col h-full">
              <div>
                <Heading component='h2'>{t('editFieldDrawer:Registering your WhatsApp number')}</Heading>
                <p>{t('editFieldDrawer:Connecting your WhatsApp number with our application')}</p>
              </div>
              <div className="flex flex-col h-full items-center grow justify-center">
                {registerWhatsAppNumber.isPending && (
                  <div className="grow">
                    <div className="loading loading-spinner loading-lg"></div>
                    <p>{t('editFieldDrawer:Registering number')}...</p>
                  </div>
                )}
                {registerWhatsAppNumber.isSuccess && (
                  <div className="grow">
                    <CheckCircleIcon className="h-6 w-6" />
                    <p>{t('editFieldDrawer:Number registered successfully!')}</p>
                  </div>
                )}
                {registerWhatsAppNumber.isError && (
                  <div className="grow">
                    <p>{t('editFieldDrawer:Error registering number')}</p>
                    <Button onClick={() => registerWhatsAppNumber.mutate} size="sm">
                      {t('Try again')}
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={handleClose}
                  size="sm"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={() => {
                    setStep('webhook')
                    updateWhatsAppNumberWebhook.mutate()
                  }}
                  disabled={!registerWhatsAppNumber.isSuccess}
                  color='primary'
                  size="sm"
                >
                  {t('Next')}
                </Button>
              </div>
            </div>
          )}
          {step === 'webhook' && (
            <div className="h-full flex flex-col">
              <div>
                <Heading component='h2'>{t('editFieldDrawer:Configuring webhook')}</Heading>
                <p>{t('editFieldDrawer:Configuring the webhook for your WhatsApp number')}</p>
              </div>
              <div className="flex flex-col items-center justify-center grow h-full">
                {updateWhatsAppNumberWebhook.isPending && (
                  <>
                    <div className="loading loading-spinner loading-lg"></div>
                    <p>{t('editFieldDrawer:Configuring')}...</p>
                  </>
                )}
                {updateWhatsAppNumberWebhook.isSuccess && (
                  <>
                    <CheckCircleIcon className="h-6 w-6" />
                    <p>{t('editFieldDrawer:Webhook configured successfully!')}</p>
                  </>
                )}
                {updateWhatsAppNumberWebhook.isError && (
                  <>
                    <p>{t('editFieldDrawer:Error configuring webhook')}</p>
                    <Button onClick={() => updateWhatsAppNumberWebhook.mutate} size="sm">
                      {t('Retry')}
                    </Button>
                  </>
                )}
              </div>
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={handleClose}
                  size="sm"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={() => {
                    setStep('confirmation')
                  }}
                  disabled={!updateWhatsAppNumberWebhook.isSuccess}
                  color='primary'
                  size="sm"
                >
                  {t('Next')}
                </Button>
              </div>
            </div>
          )}
          {step === 'confirmation' && (
            <div className="h-full flex flex-col">
              <div>
                <Heading component='h2'>{t('editFieldDrawer:WhatsApp configured')}</Heading>
                <p>{t('editFieldDrawer:WhatsApp connection finished')}</p>
              </div>
              <div className="grow flex flex-col items-center justify-center h-full">
                <CheckCircleIcon className="h-6 w-6" />
                {t('Thank you')}
              </div>
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={handleConfirmation}
                  disabled={!updateWhatsAppNumberWebhook.isSuccess}
                  color='primary'
                  size="sm"
                >
                  {t('Finish')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  )
}
