import { FieldToolConfig, FieldToolType, GetFieldToolsResultData, UUID } from '@indigohive/cogfy-types'
import { CollectionPageController } from '../../../pages/CollectionPageV2/collection-page-controller'
import { CollectionStateFieldFieldTool } from '../../../lib'
import { UpdateFieldFieldToolCommandData } from '../../../lib/commands'

export function saveFieldTool (
  type: FieldToolType,
  fieldId: UUID,
  values: Record<string, any>,
  controller?: CollectionPageController,
  fieldTools?: GetFieldToolsResultData[],
  fieldFieldTool?: CollectionStateFieldFieldTool | null
) {
  const inputs = Object.keys(fieldTools?.find((schema) => schema.type === type)!.inputsSchema.inputs ?? {})

  const config = {
    type,
    data: {
      ...inputs.reduce((acc: any, key: string) => {
        if (values[key] !== undefined && values[key] !== null && values[key] !== '') {
          acc[key] = values[key]
        }
        return acc
      }, {})
    }
  }

  if (fieldFieldTool) {
    const data: UpdateFieldFieldToolCommandData = {
      ...fieldFieldTool,
      name: values.name,
      description: values.description ?? '',
      config: config as FieldToolConfig,
      jsonSchema: JSON.parse(values.jsonSchema ?? {}),
      confirm: values.confirm ?? false
    }

    return controller?.onUpdateFieldFieldTool(data)
  }

  controller?.onCreateFieldFieldTool(
    values.name,
    values.description,
    fieldId,
    type,
    JSON.parse(values.jsonSchema ?? {}),
    config as FieldToolConfig,
    values.confirm ?? false
  )
}
