import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { FormattedDate } from '../../../components'
import clsx from 'clsx'
import { isBefore, isToday, isYesterday, startOfYesterday } from 'date-fns'
import { useAuthentication } from '../../../hooks'
import { t } from 'i18next'
import { ChatLastMessage } from '@indigohive/cogfy-types'

export type PreviewChatContentProps = {
  onClick: () => void
  textWrap?: boolean | null
  date?: string | null
  lastMessage: ChatLastMessage
  phone?: string | null
}

export function PreviewChatContent (props: PreviewChatContentProps) {
  const { onClick, textWrap, date, lastMessage, phone } = props

  const auth = useAuthentication()
  const messageSender = getMessageSender(lastMessage, auth.authenticatedUser?.id, phone)

  return (
    <div
      onClick={onClick}
      className={clsx(
        'h-full',
        'w-full',
        'px-2',
        'py-1',
        'flex',
        'items-start',
        'hover:bg-base-200',
        'active:bg-base-300',
        'gap-1',
        'text-gray-600',
        !textWrap && 'truncate'
      )}
    >
      <div className={clsx('grow', 'line-clamp-2')}>
        <span className="font-medium text-gray-600 inline-flex gap-1.5 items-center mr-1">
          <ChatBubbleLeftEllipsisIcon className="w-3 h-3" />
          {messageSender}{!textWrap && ':'}
        </span>
        {lastMessage.content}
      </div>

      <div className="text-xs text-gray-500">
        {date && isToday(date) && <FormattedDate date={date} format='p' />}
        {date && isYesterday(date) && t('Yesterday')}
        {date && isBefore(date, startOfYesterday()) && <FormattedDate date={date} format='P' />}
      </div>
    </div>
  )
}

function getMessageSender (lastMessage: ChatLastMessage, userId: any, phone?: string | null): string {
  if (lastMessage?.role === 'assistant' && phone) {
    return phone
  }

  if (lastMessage?.user?.id === userId) {
    return t('You')
  }

  if (lastMessage?.user?.name) {
    return lastMessage.user.name
  }

  return t('Assistant')
}
