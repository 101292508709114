import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { ReactNode, useState } from 'react'
import { Button } from '../Button'
import { OverlayContent } from '../OverlayContent'
import clsx, { ClassValue } from 'clsx'
import { Menu } from '../Menu'

export type SelectOverlayProps = {
  options: { label: ReactNode, value: string, disabled?: boolean }[]
  label?: ReactNode
  value?: string
  disabled?: boolean
  error?: boolean
  onChange?: (value: string) => void
  ButtonClassName?: ClassValue
}

export function SelectOverlay (props: SelectOverlayProps) {
  const { options, label, disabled, error, ButtonClassName } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [value, setValue] = useState(props.value || '')

  const option = options.find(options => options.value === value)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <label className="form-control">
        {label && (
          <div className="label">
            <span className="label-text">
              {label}
            </span>
          </div>
        )}
        <Button
          className={clsx(
            'w-full flex',
            error && 'border-error border-1',
            ButtonClassName
          )}
          size="sm"
          disabled={disabled}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <span className="text-left font-normal grow">{option?.label}</span>
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </label>

      <OverlayContent
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Menu
          className="w-full max-w-full max-h-96 overflow-y-auto"
        >
          {options.map(option => (
            <li key={option.value} className={clsx(option.disabled && 'disabled')}>
              <a
                onClick={() => {
                  setValue(option.value)
                  props.onChange?.(option.value)
                  handleClose()
                }}
                className="flex"
              >
                <span className="grow">
                  {option.label}
                </span>
                <CheckIcon
                  className={clsx(
                    'w-4 h-4',
                    option.value === value && 'visible',
                    option.value !== value && 'invisible'
                  )}
                />
              </a>
            </li>
          ))}
        </Menu>
      </OverlayContent>
    </>
  )
}
