import { useQuery } from '@tanstack/react-query'
import { TransactionEvent, UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { CollectionState, CollectionStateFieldFieldTool } from '../lib'
import { useEffect } from 'react'

export function useFieldTools (
  state: CollectionState,
  fieldId: UUID
) {
  const cogfy = useCogfy()
  const collectionId = state.id

  const getFieldTools = useQuery({
    queryKey: ['getFieldTools', collectionId, fieldId],
    queryFn: async ({ signal }) => {
      const getFieldToolsResult = await cogfy.collections.getFieldTools(collectionId, fieldId, signal)
      const fieldTools = getFieldToolsResult.data.map<CollectionStateFieldFieldTool>(({ ...fieldTool }) => ({
        fieldId,
        ...fieldTool
      }))
      state.setFieldFieldTools(fieldTools)

      return getFieldToolsResult
    }
  })

  const handleTransaction = (event: TransactionEvent) => {
    if (event.type === 'field_tool_created' && event.data.fieldId === fieldId) {
      getFieldTools.refetch()
    }
  }

  useEffect(() => {
    cogfy.transactions.onTransaction(collectionId, handleTransaction)

    return () => {
      cogfy.transactions.offTransaction(collectionId, handleTransaction)
    }
  }, [state, fieldId])

  return getFieldTools
}
