import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

export type CollectionTablePropertiesMenuButtonProps = {
  loading?: boolean
  onClick?: () => void
}

export function CollectionTablePropertiesMenuButton (props: CollectionTablePropertiesMenuButtonProps) {
  const { loading, onClick } = props

  return (
    <div
      className={clsx(
        loading ? 'cursor-not-allowed' : 'cursor-pointer',
        !loading && 'hover:bg-base-200',
        !loading && 'active:bg-base-300',
        'h-full',
        'text-gray-400',
        'flex',
        'items-center',
        'px-2.5'
      )}
      role="button"
      onClick={() => { !loading && onClick?.() }}
    >
      <EllipsisHorizontalIcon className="w-4 h-4" />
    </div>
  )
}
