import { JsonRecordProperty, JsonViewFieldConfig } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

export function JsonCell (props: FieldCellProps) {
  const { field, record, viewField } = props

  const value = (record.properties[field.id] as JsonRecordProperty)?.json?.value
  const viewFieldConfig = viewField.config as JsonViewFieldConfig
  const textWrap = viewFieldConfig?.ui?.textWrap
  const format = viewFieldConfig?.json?.textFormat

  const formattedValue = format
    ? JSON.stringify(value, null, 2)
    : JSON.stringify(value)

  return (
    <code
      className={clsx(
        'px-1 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'whitespace-nowrap truncate'
      )}
    >
      {formattedValue}
    </code>
  )
}
