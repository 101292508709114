import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { GetConnectionsQuery } from '@indigohive/cogfy-types'

export function useConnections (
  params: GetConnectionsQuery = {}
) {
  const cogfy = useCogfy()

  const getConnections = useQuery({
    queryKey: ['getConnections', params],
    queryFn: ({ signal }) => cogfy.connections.getList(
      params,
      signal
    )
  })

  return getConnections
}
