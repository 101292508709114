import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useFieldFieldTools (state: CollectionState) {
  const [fieldFieldTools, setFieldFieldTools] = useState(state.fieldFieldTools)

  useEffect(() => {
    const handleFieldToolsChanged = () => {
      setFieldFieldTools(state.fieldFieldTools)
    }

    state.addEventListener('fieldFieldToolsChanged', handleFieldToolsChanged)

    return () => {
      state.removeEventListener('fieldFieldToolsChanged', handleFieldToolsChanged)
    }
  }, [state])

  return fieldFieldTools
}
