import { format } from 'date-fns/format'
import { isDate } from 'date-fns/isDate'
import i18n from 'i18next'
import { enUS, Locale, ptBR } from 'date-fns/locale'

export type FormattedDateProps = {
  date?: Date | string | number | null
  format?: string
}

const localeMap: Record<string, Locale> = {
  en: enUS,
  'pt-BR': ptBR
}

export function FormattedDate (props: FormattedDateProps) {
  if (!props.date) {
    return <span></span>
  }

  const date = isDate(props.date) ? props.date : new Date(props.date)
  const locale = localeMap[i18n.language]

  let formatted

  try {
    formatted = format(date, props.format ?? 'P', { locale })
  } catch {
    formatted = 'Invalid date'
  }

  return (
    <span>{formatted}</span>
  )
}
