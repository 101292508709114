import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../../../pages/CollectionPageV2/collection-page-controller'
import { ChatConfigurationsValues } from '../../ChatConfigurations'
import { useCollections } from '../../../../../hooks'
import { Input, MultiSelect, ToolTip } from '../../../../../components'
import { ChatField, UUID } from '@indigohive/cogfy-types'

const minCountDocuments = 1
const maxCountDocuments = 100
const minThreshold = 0.01
const maxThreshold = 1
const validationSchema = yup.object().shape({
  threshold: yup.number().min(minThreshold).max(maxThreshold),
  countDocuments: yup.number().min(minCountDocuments).max(maxCountDocuments)
})

export type ChatConfigurationsContextProps = {
  controller: CollectionPageController
  state: CollectionState
  values: ChatConfigurationsValues
  onChange: (prop: keyof ChatConfigurationsValues, value: any) => void
}

export function ChatConfigurationsContext (props: ChatConfigurationsContextProps) {
  const { controller, state, values, onChange } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state) as ChatField
  const getCollections = useCollections({ type: 'database' })

  const collectionIds = values.collectionIds
  const threshold = values.threshold
  const countDocuments = values.countDocuments
  const collections = getCollections?.data?.data.filter(collection => collection.type !== 'folder')
  const allCollectionIds = collections?.map(collection => collection.id) ?? []
  const isCountDocumentsValid = validationSchema.isValidSync({ countDocuments })
  const isThresholdValid = validationSchema.isValidSync({ threshold })

  return (
    <>
      <span className="font-bold text-sm">
        {t('editFieldDrawer:Context')}
      </span>
      {collections && (
        <>
          <div className='flex items-center'>
            <span className="label-text">{t('Collections')}</span>
            <ToolTip
              description={t('ToolTipDescription:The collections which the chat can search for documents')}
              type="info"
            />
          </div>
          <MultiSelect
            options={collections.map(collection => ({ label: collection.name ?? 'Untitled', value: collection.id }))}
            labels={{
              selectItems: t('editFieldDrawer:Select collections'),
              allItems: t('editFieldDrawer:All collections'),
              noResultsFound: t('No collections found')
            }}
            selected={collectionIds}
            onChange={(value: string) => onChange(
              'collectionIds',
              collectionIds.includes(value as UUID) ? collectionIds.filter(id => id !== value) : [...collectionIds, value as UUID]
            )}
            onClearSelections={() => onChange('collectionIds', [])}
            onClose={() => controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, collectionIds } })}
            onSelectAll={() => onChange('collectionIds', [...allCollectionIds])}
          />
        </>
      )}
      <label className="form-control w-full pt-1">
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Minimum similarity')}</span>
          <ToolTip
            description={t('ToolTipDescription:Minimum similarity between the user question and the content of the documents')}
            type="info"
          />
        </div>
        <Input
          size="sm"
          step={0.01}
          min={0.01}
          max={1}
          type="number"
          error={!isThresholdValid}
          value={threshold}
          onChange={event => onChange('threshold', Number(event.target.value))}
          onBlur={_ => { isThresholdValid && controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, threshold } }) }}
        />
        {!isThresholdValid && (
          <span className="text-xs text-error pt-1">
            {t('editFieldDrawer:Must be between {{min}} and {{max}}', { min: minThreshold, max: maxThreshold })}
          </span>
        )}
      </label>
      <label className="form-control w-full pt-1">
        <div className='flex items-center'>
          <span className="label-text py-1">{t('editFieldDrawer:Quantity of documents')}</span>
          <ToolTip
            description={t('ToolTipDescription:Maximum quantity of document contents to be searched')}
            type="info"
          />
        </div>
        <Input
          size="sm"
          step={1}
          min={1}
          max={100}
          type="number"
          error={!isCountDocumentsValid}
          value={countDocuments}
          onChange={event => onChange('countDocuments', Number(event.target.value))}
          onBlur={_ => { isCountDocumentsValid && controller.onUpdateFieldData(selectedField.id, { chat: { ...selectedField.data?.chat, countDocuments } }) }}
        />
        {!isCountDocumentsValid && (
          <span className="text-xs text-error pt-1">
            {t('editFieldDrawer:Must be between {{min}} and {{max}}', { min: minCountDocuments, max: maxCountDocuments })}
          </span>
        )}
      </label>
    </>
  )
}
