import { FieldToolCogfyCreateRecordConfig, FieldToolInputField } from '@indigohive/cogfy-types'
import { FieldToolInputProps } from '../FieldToolDialog'
import { useCollectionFields } from '../../../hooks'
import { Select } from '../../Select'
import { Button } from '../../Button'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'

export function FieldToolFieldInput (props: FieldToolInputProps) {
  const name = props.name ?? ''
  const input = props.input as FieldToolInputField
  const fieldIds = props.values[name] as string[]
  const toolConfig = props.fieldFieldTool?.config as FieldToolCogfyCreateRecordConfig | undefined
  const multiple = Boolean(input.data?.multiple)
  const collectionId = props.values?.[input.data.collectionKey]

  const getFields = useCollectionFields(collectionId)
  const fields = getFields.data?.data ?? []
  const options = [
    { label: 'Select a field', value: '', disabled: true },
    ...fields
      .filter(field => input.data?.types?.includes(field.type))
      .map(field => ({ label: field.name, value: field.id }))
  ]

  useEffect(() => {
    if (collectionId !== toolConfig?.data?.collectionId) {
      props.handleChange?.(name, [])
    }
  }, [collectionId])

  return (
    <div className="pb-4">
      <div className="p-1 text-sm">
        {input.label}
        {input.required && <span className="text-error"> *</span>}
      </div>
      {fieldIds.map((fieldId, index) => (
        <div
          key={index}
          className="flex gap-2 items-center"
        >
          <Select
            className="my-1"
            size="sm"
            value={fieldId ?? ''}
            options={options}
            onChange={event => {
              const newFieldIds = [...fieldIds]
              newFieldIds[index] = event.target.value
              props.handleChange?.(name, newFieldIds)
            }}
          />
          {multiple && (
            <Button
              type="button"
              square
              ghost
              size="sm"
              onClick={() => {
                const newFieldIds = [...fieldIds]
                newFieldIds.splice(index, 1)
                props.handleChange?.(name, newFieldIds)
              }}
            >
              <TrashIcon className="w-4 h-4" />
            </Button>
          )}
        </div>
      ))}
      {multiple && (
        <Button
          type="button"
          className="mt-2"
          size="xs"
          disabled={!collectionId}
          onClick={() => props.handleChange?.(name, [...fieldIds, ''])}
        >
          <PlusIcon className="w-4 h-4" />
          Add field
        </Button>
      )}
    </div>
  )
}
